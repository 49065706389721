:root{
    --base-color: #63b3f8;
    --text-color:rgba(0,0,0,.54);
    --complement-color: #63b3f8;
    --menu-container:#363740;
    --menu-text-color: white;
    --border-color: rgba(193, 193, 193, 0.68);
}

ibis-header .ibis-company{
    min-width: 200px;
    border-right: 1px solid var(--border-color);
    padding: 2px 10px;
    color: var(--text-color);
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
}
ibis-header .ibis-company-arrow{
    padding: 16px 0;
}
ibis-header .ibis-arrow-right{
    padding: 9px 0px;
    color:var(--text-color);
}
ibis-header  .dx-toolbar .dx-toolbar-items-container {
    height: 56px !important;
}

ibis-header .ibis-header-toggle,
ibis-header .ibis-header-toggle dx-button.dx-state-focused,
ibis-header .ibis-header-toggle dx-button.dx-state-hover  {
    background-color: var(--base-color);    
}

ibis-header .ibis-header-toggle .dx-button-mode-contained.dx-button-normal {
    box-shadow: 0 0px;
}

ibis-header .ibis-header-toggle dx-button {
    margin-left: 9px;
    margin-right: 8px;
    background-color: var(--base-color);
}

ibis-header .ibis-header-toggle dx-button .dx-icon {
    color: white;
}

/** menu */
.menu-container .dx-treeview .dx-treeview-node[aria-level="1"] {
    font-size: 1.1em;
    border-bottom-width: 0px !important;
}

.menu-container .dx-treeview .dx-treeview-item {
    padding: 0px;
}

.menu-container .dx-treeview .dx-treeview-item .ibis-item-parent:not(.ibis-parent-selected){
    color: rgba(230, 230, 230, 0.87);
}

.menu-container .dx-treeview .dx-treeview-item .ibis-item-child,
.menu-container .dx-treeview .dx-treeview-item .ibis-item-parent {
    padding: 10px 0px;
}

ibis-menu .dx-treeview .dx-treeview-node.dx-state-selected .dx-item-content.dx-treeview-item-content{
    color: var(--base-color);
}

ibis-menu .dx-treeview .dx-treeview-node.dx-state-selected .dx-item-content.dx-treeview-item-content .dx-icon{
    color: var(--base-color);
}
ibis-menu .dx-treeview-item .dx-treeview-item-content span {
    vertical-align: top;
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child{
    background-color: var(--base-color);
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child > .dx-item.dx-treeview-item{
    height: 56px;
    font-size: 21px;
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child > .dx-item.dx-treeview-item .dx-treeview-item-content span{
    vertical-align: inherit;
} 
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child > .dx-item.dx-treeview-item > div{
    padding: 7px 0;
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child .dx-treeview-toggle-item-visibility{
    margin-top: 9px;
}
ibis-menu .dx-treeview-toggle-item-visibility, ibis-menu .dx-item-content.dx-treeview-item-content,
ibis-menu .dx-texteditor-input{
    color:var(--menu-text-color);
    padding-right: 10px;
}
ibis-menu .dx-treeview .dx-treeview-item.dx-state-hover{
    background-color: #00000038 !important;
}
ibis-menu .dx-item-content.dx-treeview-item-content > .ibis-parent-selected {
    color: var(--base-color);
}
ibis-menu .dx-treeview .dx-treeview-item {
    padding-left: 0;
    flex-direction: row-reverse;
    border-radius: 0;
}
.ibis-menu-container{
    background-color: var(--menu-container);
}

.ibis-menu-container dx-tree-view {
    padding-top: 5px;
}

.ibis-user-icon {
    margin-top: 2px;
    margin-right: -5px;
    margin-left: 5px;
}

/* popup styling */
.ibis-edit-popup .dx-toolbar {
    border-top: 1px solid #e0e0e0;
}
.ibis-edit-popup-logo {
	float: left;
	flex: 1;
}
.ibis-edit-popup-logo > img {
	width:28px; 
	height: 28px; 
	display:block
}
.ibis-edit-popup-title {
    padding: 10px 10px;
    min-height: 48px;
    background-color: var(--menu-container);
    color: var(--menu-text-color)
}
.ibis-edit-popup-title > h3 {
    flex: 4;
    margin: 5px 6px;
    text-transform: uppercase;
	float: left;
}
.ibis-edit-popup-title dx-button {
	float: right;
}

/* content styling */
.ibis-content-block {
    margin: 0px;
    /* background-color: #f7f7f7; */
    /* padding: 0px 10px; */
}
.ibis-content {
    flex-grow: 1;
    background-color: #f7f7f7;
    padding: 0px 10px;
}

/* top-right - user info*/
.ibis-user-info{
    border-left: 1px solid var(--border-color);
    display: flex;
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
.ibis-fullName-text{
    margin: 0 10px;
    color:var(--text-color);
    font-weight: bold;
}
.ibis-role-text{
    font-size: 12px;
    margin: 0 10px;
    color:var(--text-color);
}

/* footer styling */
ibis-footer {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    color: var(--text-color);
    width: 100%;
}
.ibis-footer-copy {
    width: 50%;
    text-align: right;
    display: inline-block;
}
.ibis-footer-version {
    width: 50%;
    display: inline-block;
}

/* breadcrumb styling */
ibis-breadcrumb {
    display: flex;
    padding: 12px 0px;
    font-weight: bold;
    height: 41px;
	color: var(--text-color);
}
.ibis-breadcrumb-item {
    display: flex;
    font-size: 14px;
    line-height: 1.2857;
}
.ibis-breadcrumb-item p {
    margin: 0px 5px;
}
.ibis-breadcrumb-icon {
    height: 18px;
}
/* ibis-shortcut */
ibis-shortcut {
	text-align: right;
	width: 50%;
}
ibis-shortcut .ibis-shortcut{
    margin-top: 5px;
}
ibis-shortcut .dx-tabs, ibis-shortcut .dx-tab{
	background-color: transparent;
	text-align: right;
}
.ibis-content-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
}
ibis-shortcut .dx-tabs .dx-tab {
    display: inline-block;
}

/* header styling */
ibis-header .dx-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 20px;
}
ibis-header .ibis-header-item {
    text-align: center;
}
ibis-header .ibis-header-icon {
    width: 30px;
    font-size: 20px;
    color: var(--complement-color);
    cursor: pointer;
}
ibis-header .ibis-header-icon.ibis-user-icon{
    color:var(--base-color);
}
ibis-header .dx-toolbar{
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
ibis-header .rotate-arrow{
    transform: rotate(90deg);
}
.ibis-header-popover .dx-list-item-content{
    padding: 10px;
    color: var(--text-color);
}

/* LOGIN */
ibis-login #ibis-login-box{
    left: auto !important;
    width: 300px  !important;
}
ibis-login #ibis-login-box.isLoginVisible{
    left: auto  !important;
    width: 300px  !important;
}

ibis-login #login-form{
    margin:0px 4px;
}

ibis-login .ibis-sign-in .dx-button-text{
    line-height: 17px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .04em;
}
ibis-login .ibis-apps-list .dx-item.dx-list-item{
    margin: 5px 0;    
    background-color: transparent;
    border-bottom: 1px solid #909090;
    font-size: 30px;
}
ibis-login .ibis-apps-list .dx-list-item-content{
    padding: 10px 0;
}
ibis-login .ibis-apps-list .dx-item.dx-list-item:hover{
    border-bottom: 1px solid #253648;
}
ibis-login .dx-field-item-content:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs){
    padding: 0px;
}

ibis-login .ibis-apps-list .dx-item.dx-list-item .redirect-link, 
ibis-login .ibis-apps-list .dx-item.dx-list-item .redirect-name {
    margin: 0;
    padding: 0;
}
ibis-login .redirect-name{
    font-size: 15px !important;
}
ibis-login .ibis-list-box{
    margin: 0px auto;
}
ibis-login .ibis-list-box .ibis-select-list{
    margin: 15px 0 30px;
}
ibis-login h4{
    font-size: 22px;
}
ibis-login h4, ibis-login h3{
    margin:0;
}
.ibis-logout{    
    width: 100%;
    color: white;
    margin-top: 20px;
    background-color: #253648;
    text-transform: uppercase;
}
.ibis-logout.dx-state-hover, .ibis-logout.dx-state-active {
    background-color: #253648;
}

#ibis-login-box.isLoginVisible {
    top: calc(50vh - 200px) !important;
    left: calc(50vw - 150px) !important;
}

.ibis-sign-in .dx-button{
    width: 100%;
    background: #253648; 
    color: #fff
}

.ibis-register-link a {
    font-size: 16px;
    color: #253648 !important;
    font-weight: bold;
}
