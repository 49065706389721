/* You can add global styles to this file, and also import other style files */

html, body {
    background-color: #f7f7f7 !important;
      margin: 0px;
      min-height: 100%;
      height: 100%;
    }
    
    * {
      box-sizing: border-box;
      font-family: Roboto, Arial, sans-serif;
    }

.row {
    display: flex;
    flex-wrap: wrap;
}

.margin-left-5 {
    margin-left: 5px;
}

.marg-bot-5 {
    margin-bottom: 5px;
}

.marg-bot-5 dx-button {
    font-size: 12px;
}

.container-fluid-tabs {
    margin: 10px 10px 0;
}
.calendar {
    width: calc(100% - 260px);
    margin-left: 10px;
}

.consultationTabs {
    height: calc(100% + 10px) !important;
}

.consultationTabs .dx-form .dx-list .dx-list-item {
    border: 0px;
    width: 33%;
    float: left;
}

.form-item .consent-list.dx-list .dx-list-item {
    border: 0px;
    width: 33%;
    float: left; 
}

.appRoles .dx-form .dx-list .dx-list-item {
    border: 0px;
    width: 33%;
    float: left;
}

.dx-form {
    margin: 5px 0 0 5px;
}

 
 .dx-row.dx-column-lines.dx-virtual-row { /* to not display the visual bug at the end of the grid */
    display: none;
}

.consultationTabs dx-tab-panel {
    height: 100%;
}

.procList {
    min-height: 50px;
}

.dropdownTemplate {
    border-left: 10px solid;
}

.dropdownTemplate div {
    margin-left: 10px;
}

.master-detail-caption {
    padding: 0 0 5px 10px;
    font-size: 14px;
    font-weight: bold;
}


.dx-popup-content .dx-item.dx-box-item .dx-calendar-view-month {
    display: none;
}

.patient-hr-line {
    border-color: rgba(221, 221, 221, 0);
    background-color: rgba(221, 221, 221, 1);
    color: rgba(221, 221, 221, 1);
}

.disable {
   background-color:rgb(208, 208, 208);
    opacity: 0.3;
}

.closeButton {
    right: 10px;
    top: -1px;
    cursor: pointer;
    position: absolute;
}

.closeButton dx-button {
    border:none;
}

.fullScreenButton {
    right: 5px;
    position: absolute;
}

.fullScreenButton dx-button {
    border: none;
}

.popupHeader {
    height: 30px;
}

.popupImage {
    float:left; 
    margin: 3px 0px;
    display:block;
}

.popupImage img{
    height: 18px;
}

.popupText {
    margin: 3px 0 0 10px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
}

.hideInfo {
    display: none;
}

.updatePatient {
    cursor: pointer;
    font-weight: bold;
    padding: 2px;
    
}

dx-scheduler .dx-scheduler-navigator-caption.dx-button-normal.dx-widget {
    pointer-events: none;
}

#toolbar {
    position: fixed;
    bottom: 1px;
    height: 31px;
    width: 100%;
    border-top: thin solid rgba(221, 221, 221, 1);
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #fff;
}

.procQuantity {
    height: 20px;
    width: 100px;
    margin-top: -1px;
}

.last-button {
    margin-bottom: 35px;
}

.actions {
    width: 250px;
}

.action-bar > .dx-toolbar {
    background-color: #f7f7f7;
    padding: 0px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.action-bar > .dx-toolbar .dx-toolbar-items-container {
    height: 46px;
}

.custom {
   text-decoration: none !important;
   font-size: 18px !important;
   margin-right: 5px !important;
}

.desktopView {
    display: flex;
    height: calc(100% - 5px);
}

.calendar dx-scheduler {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 650px) {

    .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit{
        width: 60px;
        min-width: 60px;
    }

    .logout {
        padding: 5px 20px 0px 20px !important;
    }

    .col-9 .menu {
        padding-right: 0 !important;
    }

    .calendar {
        width: 92vw;
        height: calc(100% - 200px);
        margin: 0;
    }

    .calendar dx-scheduler {
        height: 100%;
        width: 100%;
        order: 2 
    }

    .calendar.isDoc {
        height: calc(100% - 100px);
    }

    .row .actions {
         order: 1;
         width: 95vw;
    }

    dx-scheduler .dx-scheduler-navigator-caption.dx-button-normal.dx-widget {
        pointer-events: auto;
    }

    .row .actions dx-calendar {
        display: none;
    }

   
    .row .actions .options .selectList {
        display: inline-block !important;
        width: calc(50vw - 20px);
        height: calc(15vh - 10px);
        border: none;
    }

    .row .actions .options .selectList label{
        font-size: 14px;
        font-weight: 500;
    }

    .actionButtons {
        text-align: center;
    }

    .actionButtons dx-button .dx-button-text {
        display: none;
    }

    .mobileView {
        height: auto;
        width: 100%;
        position: absolute;
        bottom: 0;
        top: 35px; 
    }

    .slideout-container {
        width: calc(90vw - 65px);
    }

    .mobileView dx-toolbar .dx-button.dx-button-normal.dx-widget.dx-button-has-icon {
        width: 40px;
        border: none;
        margin-top: 5px;
    }

    .mobileView dx-toolbar .dx-button.dx-button-normal.dx-widget.dx-button-has-icon .dx-icon.dx-icon-menu {
        font-size: 20px;
    }

    .slideout-container dx-accordion {
        margin-top: 4px;
    }

    .consultationTabs .dx-form .dx-list .dx-list-item {
        border: 0px;
        width: 100%;
        float: left;
    }

    #focusOutside {
        margin-top: 10px;
        position: absolute;
    }
}


.userDataGrid a.dx-icon-doc, .userDataGrid a.dx-icon-group{
    font-size: 18px !important;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item-content {
    /* for default popup opening prevention */
    padding: 0 !important;
}

.appointment-tooltip {
    font-size: 14px;
    cursor: auto;
    padding: 5px
}

.appointment-tooltip dx-button {
    font-size: 11px;
}

.custom-text-box{
    width: 100%;
    display:inline-block;
  }

  .custom-button {
    position: absolute;
    border-style: none;
    right: 0px;
    top: 0px;
    vertical-align: initial;
    box-shadow: none;
  }


.consultation-toolbar .dx-toolbar-items-container{
    height:35px;
    background-color: #f7f7f7;
}

.file-list-box{
    display: inline-block;
    margin: 10px 0;
}
.file-list-box .file-name{
    padding: 5px;
    border: 1px solid #ccc;
    margin: 10px;
    cursor: pointer;
}

    dx-data-grid .dx-freespace-row {
      display: none;
  }

  .outside-schedule-text {
      color: red;
      text-decoration-style: dotted;
  }


  .layout-header {
    z-index: 1501;
    display: block;
  } 

  .calendar-tabs-bottom dx-tab-panel {
    position: absolute;
    bottom: 115px;
    left: 10px;
    max-width: 232px;
  }

  .calendar-tabs-bottom .dx-tabpanel .dx-tab {
      width: 116px !important;
  }

  /* login css */

  #loginModal{
    position: absolute;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 9000;
    
}


.login-box{
    width: 230px;
    /* transform: scale(1.2); */
    margin: 25px auto 0;
    /* height: 270px; */
}

.login-box .logo img {
    width: 100%;
}

.logo {
    text-align: center;
    margin-bottom: 20px;
}

.logo-text {
    font-size: 25px;
    font-weight: 500;
}

.login-modal {
    width: 360px;
    margin: auto auto;
    padding: 40px;
  }

  .login-footer {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
  }



.loginInput {
    background: transparent !important;
    border-bottom-color: #253648 !important;
    border-right-color: #253648 !important;
}

.userGrid td.dx-command-edit.dx-command-edit-with-icons { 
    display: flex;
  }

.dx-toolbar-center {
    margin: 0px auto !important;
    float: none !important;
}

div.dx-drawer-panel-content.dx-overlay.dx-widget.dx-visibility-change-handler > div {
    /* important pentru visual bug pe asistent, doctor view */
    width: 100% !important;
    height: 100% !important;
}

.form-card {
    width: 100%;
    height: 100%;
}

.dx-form > .dx-item.dx-box-item{
    height: 37px;
    padding-top: 5px !important;
}

.dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-top: 0px !important;
}

.dx-field-item-content:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs) {
    padding-top: 0px !important;
}

.dx-item.dx-toolbar-item.dx-toolbar-label{
    max-width: initial !important;
}

#scheduler .dx-scheduler-cell-sizes-vertical {
    height: 40px;
}


.content {
    line-height: 1.5;
  }
.content h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .content-block {
    padding: 0 10px;
  }
  .content-block .screen-x-small {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .content-block.footer {
    margin-top: 10px;
  }
  
  .responsive-paddings {
    padding: 20px;
  }
  .responsive-paddings .screen-large {
    padding: 40px;
  }
  
  .dx-card.wide-card {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    border-right: 0;
    border-left: 0;
  }

/* LOGO MODAL */
.logo-loading{
    position: fixed;
    top: 40vh;
    left: 35vw;
    width: 25vw;
}
.loading-modal{
    width: 100vw;
    height: 100vh;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}
@keyframes fadeInOpacity {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}


.unavailable-cell {
    background-color: #f7f7f7 !important;
    pointer-events: none !important;     
}